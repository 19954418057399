









































































































































import UsuarioForm from "./UsuarioForm.script";
import hmr from "vue2-hmr/dist";

export default hmr(UsuarioForm, module);
