









































































































































































































































































































































































































































































































































































































































































































































import HistoricoPerdaFisica from "./HistoricoPerdaFisica.script";
import hmr from "vue2-hmr/dist";

export default hmr(HistoricoPerdaFisica, module);
