import SalaVacina from "./salaVacina";
import StatusPerdaFisica from "./statusPerdaFisica";
import Unidade from "./unidade";
import Usuario from "./usuario"

class PerdaFisica {
    id : null
    idResponsavelRegistro : null
    responsavelRegistro : Usuario = new Usuario();
    idTipoOcorrencia : null
    idTipoLocalOcorrencia : null
    idUnidade : any
    unidade: Unidade = new Unidade();
    codigoRetorno : null
    dataOcorrencia : null
    telefone : null
    email : null
    idSalaVacina : null
    salaVacina : SalaVacina = new SalaVacina();
    nomeCrie : null
    trajetoOcorrencia : null
    dataDescongelamento : null
    informacoesComplementares : null
    especificarParticulaEstranha : null
    formaFarmaceuticaDivergente : null
    idTipoDoseApresentada : null
    qtdDoseMais : null
    qtdDoseMenos : null
    dosesUsadas : null
    dataRegistroTempUltimaRecomendada : null
    tempMomentoRegistrada : null
    inicioRegistroTemp : null
    fimRegistroTemp : null
    idTipoOcorreuEm : null
    tempMomentoOcorrencia : null
    tempMinDuranteOcorrencia : null
    visualizadoCongelamento : null
    tempMaxDuranteOcorrencia : null
    idTipoMotivo : null
    motivoOutro : null
    prodAlteracaoTempAntes : null
    prodAlteracaoTempAntesTodos : null
    prodAlteracaoTempAntesData : null
    prodAlteracaoTempAntesMaxCelsius : null
    prodAlteracaoTempAntesMinCelsius : null
    algumaObsPerguntaAnterior : null
    obsPerguntaAnterior : null
    registroNotivisa : null
    outraAlteracaoGeral : null
    outraAlteracaoFisicoQuimica: null
    outroLocalConservacao: null
    outraEmbalagem: null
    outraCondutaAdotadaFrenteOcorrenciaAtual: null
    identificarRedeFrioImunoEncaminhado: null
    lastStatusPerdasFisicas: StatusPerdaFisica
    subMotivoOutro
    idTipoMedidaAdotada: null
    respostaConduta: null
    outroLocalOcorrencia: null
    outroInstrumento: null
    observacaoGerencia: null
    periodo: any
}

export default PerdaFisica